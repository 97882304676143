<template>
	<v-card flat class="">
		<v-data-table :headers="table.headers" :items="table.items" :options.sync="tableOptions"
			:server-items-length="table.totalItems" :loading="table.loading" class="elevation-1">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Halaman</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="add()">
								Tambah Item
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<!-- <v-container> -->
									<v-row>
										<!-- <v-col cols="12">
											<v-text-field v-model="form.editedItem.nama" outlined dense hide-details=""
												label="Nama :"></v-text-field>
										</v-col> -->
										<v-col cols="12" sm="6" md="6">
											<v-autocomplete v-model="form.editedItem.id_institusi" :items="institusi_pendidikans"
												outlined dense label="Institusi Pendidikan :" item-text="nama"
												item-value="id" hide-details=""></v-autocomplete>
										</v-col>
										<v-col cols="12" sm="6" md="6">
											<v-autocomplete v-model="form.editedItem.id_prodi" :items="prodis" outlined dense
												label="Prodi :" item-text="nama" item-value="id" hide-details=""></v-autocomplete>
										</v-col>
										<v-col cols="12" sm="6" md="6">
											<v-select v-model="form.editedItem.profesi" outlined hide-details dense
												label="Kelompok :" :items="form.profesis" class="mb-3"></v-select>
										</v-col>
										<v-col cols="12" sm="6" md="6">
											<v-autocomplete
												v-if="form.editedItem.profesi !== 'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain'"
												v-model="form.editedItem.stase_id" :items="form.stases" outlined dense
												hide-details label="Stase/Prodi :" item-text="text"
												item-value="id"></v-autocomplete>
										</v-col>
										<v-col cols="12" sm="6" md="6">
											<v-text-field v-model="form.editedItem.tanggal_awal" type="date" outlined
												label="Tanggal Awal :"></v-text-field>
										</v-col>
										<v-col cols="12" sm="6" md="6">
											<v-text-field v-model="form.editedItem.tanggal_akhir" type="date" outlined
												label="Tanggal Akhir :"></v-text-field>
										</v-col>
									</v-row>
								<!-- </v-container> -->
							</v-card-text>
							<v-card-actions>
								<v-btn color="primary" @click="save()">
									Simpan
								</v-btn>
								<v-btn color="error" v-if="form.editedItem.flag_end == 0" @click="switchKeaktifan(0)">
									Non Aktifkan
								</v-btn>
								<v-btn color="success" v-if="form.editedItem.flag_end == 1" @click="switchKeaktifan(1)">
									Aktifkan
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:item.institusi="{ item }">
				{{ item.nama_institusi }}/ {{ item.nama_prodi }}
			</template>
			<template v-slot:item.stase="{ item }">
				{{ item.nama_stase }}, {{ item.stase }}
			</template>
			<template v-slot:item.tanggal_awal="{ item }">
				{{ formatDate(item.tanggal_awal) }}
			</template>
			<template v-slot:item.tanggal_akhir="{ item }">
				{{ formatDate(item.tanggal_akhir) }}
			</template>
			<template v-slot:item.flag_end="{ item }">
				<span v-if="item.flag_end == 0">Aktif</span>
				<span v-else>Non Aktif</span>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-icon small class="mr-2" @click="editItem(item)">
					{{ icons.mdiPencil }}
				</v-icon>
				<router-link :to="{ name: 'AdminPraktikAnggota', params: { id: item.id } }">
					<v-icon small>
						{{ icons.mdiEye }}
					</v-icon>
				</router-link>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="getDataFromApi">
					Reset
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
// import UploadDokumenRegistrasi from '@/components/upload/UploadDokumenRegistrasi.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
	components: {
		// UploadDokumenRegistrasi,
	},
	data: () => ({
		icons: {
			mdiEye,
			mdiPencil,
		},
		tableOptions: {},
		table: {
			loading: true,
			headers: [
				{
					text: 'No',
					align: 'start',
					sortable: false,
					value: 'no',
				},
				{ text: 'Institusi', value: 'institusi' },
				{ text: 'Kelompok', value: 'profesi' },
				{ text: 'Stase/Prodi', value: 'stase' },
				{ text: 'Tanggal Awal', value: 'tanggal_awal' },
				{ text: 'Tanggal Akhir', value: 'tanggal_akhir' },
				{ text: 'Status', value: 'flag_end' },
				{ text: 'Aksi', value: 'actions' },
			],
			items: [],
			totalItems: 0,
		},
		dialog: false,
		// dialogDelete: false,
		form: {
			editedIndex: -1,
			editedItem: {
				id_institusi: '',
				id_prodi: '',
				nama: '',
				profesi: '',
				stase_id: 0,
				tanggal_awal: '',
				tanggal_akhir: '',
			},
			defaultItem: {
				id_prodi: '',
				id_prodi: '',
				nama: '',
				profesi: '',
				stase_id: 0,
				tanggal_awal: '',
				tanggal_akhir: '',
			},
			profesis: [
				'Dokter',
				'PPDS',
				'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain',
			],
			stases: [],
		},
		institusi_pendidikans: [],
		prodis: [],
	}),
	watch: {
		tableOptions: {
			handler() {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	computed: {
		formTitle() {
			return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
		},
		// user() {
		// 	return this.$store.getters.user
		// },
	},
	created() {
		this.getStase()
		this.getInstitusiPendidikan()
		this.getProdi()
	},
	methods: {
		getInstitusiPendidikan() {
			axiosGetAuth('api/RegisterLanjutan/getInstitusiPendidikans')
				.then(response => {
					if (response.code === 200) {
						this.institusi_pendidikans = response.datas
					} else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
		getProdi() {
			axiosGetAuth('api/RegisterLanjutan/getProdis')
				.then(response => {
					if (response.code === 200) {
						this.prodis = response.datas
					} else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
		getStase() {
			axiosGetAuth('api/Praktik/getStase')
				.then(response => {
					this.form.stases = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		save() {
			if (this.form.editedIndex > -1) {
				const fmData = new FormData()
				fmData.append('id', this.form.editedItem.id)
				fmData.append('nama', this.form.editedItem.nama)
				fmData.append('id_institusi', this.form.editedItem.id_institusi)
				fmData.append('id_prodi', this.form.editedItem.id_prodi)
				fmData.append('profesi', this.form.editedItem.profesi)
				fmData.append('stase_id', this.form.editedItem.stase_id)
				fmData.append('tanggal_awal', this.form.editedItem.tanggal_awal)
				fmData.append('tanggal_akhir', this.form.editedItem.tanggal_akhir)
				axiosPostAuth('api/Praktik/updatePraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.dialog = false
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			} else {
				const fmData = new FormData()
				fmData.append('nama', this.form.editedItem.nama)
				fmData.append('id_institusi', this.form.editedItem.id_institusi)
				fmData.append('id_prodi', this.form.editedItem.id_prodi)
				fmData.append('profesi', this.form.editedItem.profesi)
				fmData.append('stase_id', this.form.editedItem.stase_id)
				fmData.append('tanggal_awal', this.form.editedItem.tanggal_awal)
				fmData.append('tanggal_akhir', this.form.editedItem.tanggal_akhir)
				axiosPostAuth('api/Praktik/addPraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		getDataFromApi() {
			const { page, itemsPerPage } = this.tableOptions
			const fmData = new FormData()
			fmData.append('page', page)
			fmData.append('itemsPerPage', itemsPerPage)
			fmData.append('search', '')
			axiosPostAuth('api/Praktik/getPraktik_datatable', fmData)
				.then(response => {
					this.table.items = response.data
					this.table.totalItems = response.total
					this.table.loading = false
				})
				.catch(error => {
					alert(error)
				})
		},
		add() {
			this.form.editedItem = Object.assign({}, this.form.defaultItem)
			this.form.editedIndex = -1
		},
		editItem(item) {
			this.form.editedIndex = this.table.items.indexOf(item)
			this.form.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		switchKeaktifan(flag_end) {
			let conf = true
			if (flag_end == 0) {
				conf = confirm("Apakah yakin akan menonaktifkan?")
			} else {
				conf = confirm("Apakah yakin akan mengaktifkan?")
			}
			if (conf) {
				const fmData = new FormData()
				fmData.append('id', this.form.editedItem.id)
				fmData.append('flag_end', this.form.editedItem.flag_end)
				axiosPostAuth('api/Praktik/updateStatus', fmData)
					.then(response => {
						this.getDataFromApi()
						this.dialog = false
					})
					.catch(error => {
						alert(error)
					})
			}
		},
	},
}
</script>